import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import TopNavigator from './components/TopNavigator';

export default function About() {
    return (
        <>
            <TopNavigator />
            <Container justify="center">
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h2">Wonder Sketch 是专业的 UI 设计平台</Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}