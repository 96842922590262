import { useEffect, useState } from 'react';

export default function Bolt() {
    function handleOnMessage(event) {
        if (event.data === 'appLaunched') {
            setLaunching(false);
            window.removeEventListener('message', handleOnMessage);
        }
    }

    function handleOnResize() {
        setViewPortWidth(document.documentElement.clientWidth);
        setViewPortHeight(document.documentElement.clientHeight);
    }

    const [launching, setLaunching] = useState(true);
    const [viewPortWidth, setViewPortWidth] = useState(document.documentElement.clientWidth);
    const [viewPortHeight, setViewPortHeight] = useState(document.documentElement.clientHeight);

    useEffect(() => {
        window.addEventListener('message', handleOnMessage);
        window.addEventListener('resize', handleOnResize);

        return () => {
            window.removeEventListener('resize', handleOnResize);
            window.removeEventListener('message', handleOnMessage);
        };
    });

    const minWidth = 641;
    const minHeight = 480;

    return (
        <>
            {launching &&
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img src="images/spinner.gif" alt='loading' />
                    <h3 style={{ color: 'grey' }}>正在加载...</h3>
                </div>
            }

            {!launching && (viewPortWidth < minWidth || viewPortHeight < minHeight) &&
                <div
                    style={{
                        backgroundColor: 'black',
                        opacity: 0.8,
                        position: 'absolute',
                        zIndex: 1,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h3 style={{ color: 'white' }}>请放大浏览器窗口</h3>
                </div>
            }

            <div style={{
                overflow: 'hidden',
                position: 'absolute',
                left: 0,
                top: 0,
                width: viewPortWidth,
                height: viewPortHeight
            }}
            >
                <iframe
                    id="app-frame"
                    src="/bolt/index.html"
                    title='app'
                    style={{
                        borderWidth: 0,
                        width: Math.max(minWidth, viewPortWidth),
                        height: Math.max(minHeight, viewPortHeight),
                    }}
                />
            </div>
        </>
    );
}
