import TopNavigator from './components/TopNavigator';
import Footer from './components/Footer';
import Download from './Download';

export default function Home() {
    return (
        <>
            <TopNavigator />
            <Download />
            <Footer />
        </>
    );
}
