import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         marginBottom: 20,
//     },
//     menuButton: {
//         marginRight: theme.spacing(2),
//     },
//     title: {
//         flexGrow: 1,
//     },
// }));

export default function TopNavigator() {
    return (
        <div>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Wonder Sketch</Typography>
                    <Button color="inherit" component={RouterLink} to="/">首页</Button>
                    <SolutionMenuButton color="inherit" >Menu</SolutionMenuButton>
                    {/* <Button color="inherit" href="/bolt" target="_blank">登录 Bolt</Button> */}
                    <Button color="inherit" component={RouterLink} to="/bolt">登录</Button>
                    <Button color="inherit" component={RouterLink} to="/about">关于</Button>
                    <Button color="inherit" component={RouterLink} to="/download">下载</Button>
                    <Button color="inherit" component={RouterLink} to="/signin">登录</Button>
                    <Button color="inherit" component={RouterLink} to="/signup">注册</Button>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    );
}

function SolutionMenuButton() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                endIcon={<ArrowDropDownIcon />}
                color="inherit"
                aria-controls="solution-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                解决方案
            </Button>
            <Menu
                id="solution-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>在线教学</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>在线培训</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>视频会议</MenuItem>
            </Menu>
        </>
    );
}
