import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Button from '@mui/material/Button';
import Home from './Home';
import Download from './Download';
import Solution from './Solution';
import About from './About';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Bolt from './Bolt';

export default function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="solution" element={<Solution />} />
          <Route path="bolt" element={<Bolt />} />
          <Route path="about" element={<About />} />
          <Route path="users" element={<Download />} />
          <Route path="download" element={<Download />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
        </Routes>
      </div>
    </BrowserRouter>
  );

  return (
    <div className="App">
      <Button variant="contained" color="primary">
        你好，世界aDc
      </Button>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
