import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TopNavigator from './components/TopNavigator';

export default function Download() {
    return (
        <>
            <TopNavigator />
            <Container>
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h3" component="h3" align="center" gutterBottom><b>下载 Wonder Sketch</b></Typography>
                    <Typography align="center">使用 Wonder Sketch ，随时与任何位置的任何人进行协同工作。</Typography>
                    <Typography align="center">专业，协作，多平台</Typography>
                </Box>
                <Grid container justify="center" spacing={3}>
                    <Grid item>
                        <DownloadCard
                            logo="/images/macos.svg"
                            title="macOS"
                            content={'macOS 10.13及以上系统'}
                        />
                    </Grid>
                    <Grid item>
                        <DownloadCard
                            logo="/images/windows.svg"
                            title="Windows"
                            content={'Windows 7(SP1) 64位 / Windows 10 64位 / Windows 11及以上系统'}
                        />
                    </Grid>
                    <Grid item>
                        <DownloadCard
                            logo="/images/android.svg"
                            title="安卓"
                            content={'8.0及以上系统'}
                        />
                    </Grid>
                    <Grid item>
                        <DownloadCard
                            logo="/images/ios.svg"
                            title="iOS"
                            content={'iOS 10及以上系统'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

function DownloadCard(props) {
    return (
        <Card sx={{ width: 250 }} raised>
            <CardHeader
                title={props.title}
                titleTypographyProps={{ align: "center" }}
            />
            <CardContent sx={{ minHeight: 200 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <img src={props.logo} alt="" />
                </Box>
                <Typography color="text.secondary">{props.content}</Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" color="primary">下载</Button>
            </CardActions>
        </Card>
    );
}
