import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Footer() {
    return (
        <div>
            <Container>
                <Typography align="center">-</Typography>
                <Typography align="center">-</Typography>
                <Typography align="center"> </Typography>
                <Typography variant="body1" component="div" align="center">上海攒耀科技有限公司</Typography>
                <Typography variant="body1" component="div" align="center">沪ICP备2023012640号-3</Typography>
                <p />
                <Typography variant="body1" component="div" align="center">联系我们</Typography>
                <Typography variant="body1" component="div" align="center">欢迎联系我们咨询交流</Typography>
                <Typography variant="body1" component="div" align="center">18516602500</Typography>
                {/* <Typography variant="body1" component="div" align="center">邮箱：contact@js.design</Typography> */}
                <Typography variant="body1" component="div" align="center">地址：上海市青浦区马家桥路299弄77号301</Typography>
            </Container>
        </div>
    );
}